import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import Main from '../../layouts/Main'
import { handleGoogleCallback, isLoggedIn } from '../../utils/authentication'
import { Transition, PurpleArea, Button } from '../../components'
import { API_URL } from '../../utils/config'

export default ({ location }) => {
  const queryString = location.search
  const showLogin = queryString.length === 0

  const clickLogin = () => {
    window.location = `${API_URL}/login/google`
  }

  useEffect(() => {
    if (queryString.length > 0) {
      handleGoogleCallback(queryString).then(() => {
        window.location = '/'
      })
    } else if (isLoggedIn()) {
      navigate('/')
    }
  }, [])

  return (
    <Main full noMenu>
      <Transition>
        {showLogin && (
          <PurpleArea style={{ minHeight: '100vh' }}>
            <div className='pt-5 pb-5 container dark-theme'>
              <h1>Hjarta Kosmos & Kaos</h1>

              <div className='row'>
                <div className='col-md-6 col-sm-9'>
                  <p className='pb-5'>
                    Óheimill aðgangur er með öllu óheimill og verulega mikið
                    stranglega bannaður. Forbidden. Keep out.
                  </p>

                  <Button onClick={clickLogin}>Login with goggel</Button>
                </div>
              </div>
            </div>
          </PurpleArea>
        )}
      </Transition>
    </Main>
  )
}
